// src/styles/global-styles.ts
import { createGlobalStyle, DefaultTheme } from 'styled-components';

const darkTheme = {
  primary: '#436aba',
  secondary: '#42a5f5',
  background: '#121212',
};

const lightTheme = {
  primary: '#436aba',
  secondary: '#42a5f5',
  background: '#fafafa',
};

interface CustomTheme extends DefaultTheme {
  themeMode: 'light' | 'dark';
}

export const GlobalStyle = createGlobalStyle<CustomTheme>`
  :root {
    --primary-color: ${({ theme }) =>
      theme.themeMode === 'dark' ? darkTheme.primary : lightTheme.primary};
    --secondary-color: ${({ theme }) =>
      theme.themeMode === 'dark' ? darkTheme.secondary : lightTheme.secondary};
    --background-color: ${({ theme }) =>
      theme.themeMode === 'dark'
        ? darkTheme.background
        : lightTheme.background};
  }

  html, body {
    height: 100%;
    width: 100%;
    overflow-y: auto; /* Allow vertical scrolling */
    margin: 0;
    background-color: var(--background-color);
    color: var(--primary-color);
  }

  body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label {
    font-family: Georgia, Times, 'Times New Roman', serif;
    line-height: 1.5em;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }
`;
