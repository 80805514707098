import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { configureAppStore } from './store/configureStore';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { StrictMode } from 'react';
import { App } from './App';

const store = configureAppStore();

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <HelmetProvider>
      <StrictMode>
        <App />
      </StrictMode>
    </HelmetProvider>
  </Provider>,
);
