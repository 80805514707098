import * as React from 'react';
import { Gearbox7 } from '../../components/Gearbox7';
import geARBox7 from '../../assets/geARBox7.gif';
import reactLogo from '../../assets/react.svg';
import typeScriptLogo from '../../assets/typescript.svg';
import reduxLogo from '../../assets/redux.svg';
import sagaLogo from '../../assets/redux-saga.svg';
import routerLogo from '../../assets/react-router.svg';
import muiLogo from '../../assets/material-ui.svg';
import viteLogo from '../../assets/vite.svg';
import { TestReduxComponent } from '../../components/TestReduxComponent';
import { TestSagaComponent } from '../../components/TestSagaComponent';
import { Box, Button, IconButton, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useState } from 'react';
import { Add, Remove } from '@mui/icons-material';
import Link from '@mui/material/Link';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectError,
  selectIsLoading,
  selectTodos,
} from '../../components/TestSagaComponent/slice/selectors';
import { useTestReduxComponentSlice } from '../../components/TestReduxComponent/slice';
import { useTestSagaComponentSlice } from '../../components/TestSagaComponent/slice';
import '@fontsource/space-mono';
import { Todo } from 'components/TestSagaComponent/slice/types';

export const Gearbox = () => {
  const [count, setCount] = useState(0);

  const dispatch = useDispatch();
  const { actions: testReduxComponentActions } = useTestReduxComponentSlice();
  const { actions: testSagaComponentActions } = useTestSagaComponentSlice();
  const todos = useSelector(selectTodos);
  const isLoading = useSelector(selectIsLoading);
  const error = useSelector(selectError);

  return (
    <>
      <Box
        sx={{
          maxWidth: '1280px',
          margin: '0 auto',
          padding: '2rem',
          textAlign: 'center',
          minHeight: '80%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Gearbox7 />
        <Box height={'1rem'} />
        <Icons7 />
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          justifyContent: 'center',
          alignItems: 'center',
          minWidth: '50vh',
        }}
      >
        <Grid
          container
          sx={{ padding: 1, justifyContent: 'center' }}
          spacing={2}
        >
          <Grid>
            <UseStateDemoBox setCount={setCount} count={count} />
          </Grid>
          <Grid>
            <TestReduxComponent />
          </Grid>
          <Grid>
            <CustomButtons
              setCount={setCount}
              dispatch={dispatch}
              testReduxComponentActions={testReduxComponentActions}
              testSagaComponentActions={testSagaComponentActions}
            />
          </Grid>
        </Grid>
      </Box>
      {isLoading && <CustomLoadingIndicator />}
      {error && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: 2,
            fontFamily: 'Space Mono, sans-serif',
            width: '600px',
            maxHeight: '150px',
            maxWidth: '600px',
            margin: '0 auto',
          }}
        >
          Saga Demo:<span style={{ color: 'red' }}>&nbsp;{error}</span>
        </Box>
      )}
      {todos && <DisplayTodos todos={todos} />}
      <CustomFooter />
    </>
  );
};

// Function constants definitions

// const Gearbox7 = () => {
//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         flexDirection: 'row',
//         alignItems: 'baseline',
//         justifyContent: 'center',
//       }}
//     >
//       <Typography
//         variant="h3"
//         component="div"
//         fontFamily="Space Mono, sans-serif"
//         fontWeight="bold"
//         sx={{
//           background:
//             'linear-gradient(90deg, #122e68 0%, #436aba 25%, #7a9add 50%, #c1d7ff 75%, #aaa57d 100%)',
//           WebkitBackgroundClip: 'text',
//           WebkitTextFillColor: 'transparent',
//         }}
//       >
//         ge<span style={{ fontVariantCaps: 'all-small-caps' }}>ARBOX</span>
//       </Typography>
//       <img
//         src="./src/assets/geARBox7.gif"
//         alt="geARBox"
//         height="50"
//         style={{
//           filter: 'drop-shadow(0 0 1em #61dafbcb)',
//         }}
//       />
//     </Box>
//   );
// };

interface CustomIconProps {
  href: string;
  imgSrc: string;
  imgAlt: string;
  filter?: boolean;
  height?: string;
  spin?: boolean;
}

export const CustomIcon = ({
  href,
  imgSrc,
  imgAlt,
  filter = false,
  height = '3em',
  spin = false,
}: CustomIconProps) => (
  <Link href={href} target="_blank">
    <img
      src={imgSrc}
      alt={imgAlt}
      style={{
        height,
        transition: 'filter 300ms',
        filter: filter ? 'drop-shadow(0 0 2em #61dafbaa)' : 'none',
        animation: spin ? 'spin 7s linear infinite' : 'none',
      }}
    />
    <style>
      {`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}
    </style>
  </Link>
);

const Icons7 = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '1.5em',
        justifyContent: 'center',
      }}
    >
      <CustomIcon
        href="https://react.dev"
        imgSrc={reactLogo}
        imgAlt="React logo"
      />
      <CustomIcon
        href="https://vitejs.dev"
        imgSrc={viteLogo}
        imgAlt="Vite logo"
      />
      <CustomIcon
        href="https://www.typescriptlang.org/"
        imgSrc={typeScriptLogo}
        imgAlt="TS logo"
      />
      <CustomIcon
        href="https://redux-toolkit.js.org/"
        imgSrc={reduxLogo}
        imgAlt="Redux logo"
      />
      <CustomIcon
        href="https://redux-saga.js.org/"
        imgSrc={sagaLogo}
        imgAlt="Saga logo"
      />
      <CustomIcon
        href="https://reactrouter.com/"
        imgSrc={routerLogo}
        imgAlt="Router logo"
      />
      <CustomIcon href="https://mui.com/" imgSrc={muiLogo} imgAlt="MUI logo" />
    </Box>
  );
};

interface UseStateDemoBoxProps {
  setCount: React.Dispatch<React.SetStateAction<number>>;
  count: number;
}

const UseStateDemoBox = ({ setCount, count }: UseStateDemoBoxProps) => {
  return (
    <Box
      sx={{
        border: '2px solid',
        borderImage:
          'linear-gradient(90deg, #122e68 0%, #436aba 25%, #7a9add 50%, #c1d7ff 75%, #aaa57d 100%)',
        borderImageSlice: 1,
        padding: 2,
        width: '200px',
        margin: '0 auto',
      }}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Button
          sx={{
            color: '#436aba',
            borderColor: '#436aba',
            backgroundColor: 'transparent',
            '&:hover': {
              borderColor: '#436aba',
              backgroundColor: 'transparent',
            },
            fontFamily: 'Space Mono, sans-serif',
          }}
        >
          useState Demo
        </Button>
      </Grid>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Grid>
          <IconButton
            color="warning"
            onClick={() => setCount((count: number) => count - 1)}
          >
            <Remove />
          </IconButton>
        </Grid>
        <Grid>
          <Typography sx={{ fontFamily: 'Space Mono, sans-serif' }}>
            {count}
          </Typography>
        </Grid>
        <Grid>
          <IconButton
            color="success"
            onClick={() => setCount((count: number) => count + 1)}
          >
            <Add />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

interface CustomButtonsProps {
  setCount: React.Dispatch<React.SetStateAction<number>>;
  dispatch;
  testReduxComponentActions;
  testSagaComponentActions;
}

const CustomButtons = ({
  setCount,
  dispatch,
  testReduxComponentActions,
  testSagaComponentActions,
}: CustomButtonsProps) => {
  return (
    <Grid container spacing={2} direction={'column'}>
      <Button
        variant="outlined"
        sx={{
          color: '#aaa57d',
          borderColor: '#aaa57d',
          '&:hover': {
            borderColor: '#aaa57d',
            backgroundColor: 'transparent',
          },
          backgroundColor: 'transparent',
          fontFamily: 'Space Mono, sans-serif',
        }}
        onClick={() => {
          setCount(0);
          dispatch(testReduxComponentActions.resetCounter());
          dispatch(testSagaComponentActions.resetTodos());
        }}
      >
        Reset
      </Button>
      <TestSagaComponent />
    </Grid>
  );
};
const CustomLoadingIndicator = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 2,
        fontFamily: 'Space Mono, sans-serif',
        width: '600px',
        maxHeight: '150px',
        maxWidth: '600px',
        margin: '0 auto',
      }}
    >
      <img
        src={geARBox7}
        alt="geARBox7"
        height="50"
        style={{
          filter: 'drop-shadow(0 0 1em #61dafbcb)',
          paddingRight: 5,
        }}
      />
      Saga Demo:{' '}
      <span style={{ color: '#aaa57d' }}>&nbsp;Fetching data...</span>
    </Box>
  );
};

interface DisplayTodosProps {
  todos: Todo[];
}

const DisplayTodos = ({ todos }: DisplayTodosProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        padding: 2,
      }}
    >
      <Box
        sx={{
          width: '600px',
          maxHeight: '150px',
          overflowY: 'scroll',
          whiteSpace: 'pre-wrap',
          background: '#c1d7ff2e',
          borderRadius: '7px',
          borderImageSlice: 1,
          padding: 2,
          maxWidth: '600px',
          margin: '0 auto',
        }}
      >
        <Typography
          component="pre"
          color="#aaa57d"
          fontFamily="Space Mono, sans-serif"
          fontSize="0.8rem"
          sx={{
            margin: 0,
          }}
        >
          {JSON.stringify(todos, null, 2)}
        </Typography>
      </Box>
    </Box>
  );
};

const CustomFooter = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        background:
          'linear-gradient(90deg, #122e68 0%, #436aba 25%, #7a9add 50%, #c1d7ff 75%, #aaa57d 100%)',
        color: '#122e68', // Text color
        textAlign: 'center',
        padding: '1rem',
        fontFamily: 'Space Mono, sans-serif',
      }}
    >
      <Typography fontFamily="Space Mono, sans-serif">
        geᴀʀʙox7 | Assembled by ARB
      </Typography>
    </Box>
  );
};
