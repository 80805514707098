import { createSelector } from '@reduxjs/toolkit';

import { initialState } from '.';
import { RootState } from '../../../types';

const selectSlice = (state: RootState) =>
  state?.testReduxComponent || initialState;

export const selectTestReduxComponent = createSelector(
  [selectSlice],
  state => state,
);

export const selectCounter = createSelector(
  [selectSlice],
  state => state?.counter,
);
