import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import {
  useInjectReducer,
  useInjectSaga,
} from '../../../utils/redux-injectors';
import { testSagaComponentSaga } from './saga';
import { TestSagaComponentState, Todo } from './types';

export const initialState: TestSagaComponentState = {
  data: null,
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'testSagaComponent',
  initialState,
  reducers: {
    // someAction(state, action: PayloadAction<any>) {},
    fetchTodosStarted(state) {
      state.data = null;
      state.isLoading = true;
      state.error = null;
    },
    fetchTodosSuccess(state, action: PayloadAction<Todo[]>) {
      state.data = action.payload;
      state.isLoading = false;
    },
    fetchTodosFailed(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetTodos(state) {
      Object.assign(state, initialState);
    },
  },
});

export const { actions: testSagaComponentActions } = slice;

export const useTestSagaComponentSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: testSagaComponentSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useTestSagaComponentSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
