/**
 *
 * WordBee
 *
 */
import * as React from 'react';

export function WordBee() {
  const style = { padding: '1rem' };

  return <div style={style}>This is WordBee.</div>;
}
