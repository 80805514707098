/**
 *
 * TestSagaComponent
 *
 */
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useTestSagaComponentSlice } from './slice';
import { Button } from '@mui/material';

export function TestSagaComponent() {
  const dispatch = useDispatch();
  const { actions: testSagaComponentActions } = useTestSagaComponentSlice();

  return (
    <Button
      variant="outlined"
      sx={{
        color: '#436aba',
        borderColor: '#436aba',
        backgroundColor: 'transparent',
        '&:hover': {
          borderColor: '#436aba',
          backgroundColor: 'transparent',
        },
        fontFamily: 'Space Mono, sans-serif',
      }}
      onClick={() => {
        dispatch(testSagaComponentActions.fetchTodosStarted());
      }}
    >
      Saga Demo
    </Button>
  );
}
