/**
 *
 * TestReduxComponent
 *
 */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCounter } from './slice/selectors';
import { Box, Button, IconButton, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Add, Remove } from '@mui/icons-material';
import { useTestReduxComponentSlice } from './slice';
import '@fontsource/space-mono';

export const TestReduxComponent = () => {
  const dispatch = useDispatch();
  const { actions: testReduxComponentActions } = useTestReduxComponentSlice();
  const counter = useSelector(selectCounter);

  return (
    <>
      <Box
        sx={{
          border: '2px solid',
          borderImage:
            'linear-gradient(90deg, #122e68 0%, #436aba 25%, #7a9add 50%, #c1d7ff 75%, #aaa57d 100%)',
          borderImageSlice: 1,
          padding: 2,
          width: '200px',
          margin: '0 auto',
        }}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Button
            sx={{
              color: '#436aba',
              borderColor: '#436aba',
              backgroundColor: 'transparent',
              '&:hover': {
                borderColor: '#436aba',
                backgroundColor: 'transparent',
              },
              fontFamily: 'Space Mono, sans-serif',
            }}
          >
            Redux Demo
          </Button>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Grid>
            <IconButton
              color="warning"
              onClick={() => {
                dispatch(testReduxComponentActions.decrementCounter());
              }}
            >
              <Remove />
            </IconButton>
          </Grid>
          <Grid>
            <Typography sx={{ fontFamily: 'Space Mono, sans-serif' }}>
              {counter}
            </Typography>
          </Grid>
          <Grid>
            <IconButton
              color="success"
              onClick={() => {
                dispatch(testReduxComponentActions.incrementCounter());
              }}
            >
              <Add />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
