// import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '../../../utils/@reduxjs/toolkit';
import {
  useInjectReducer,
  useInjectSaga,
} from '../../../utils/redux-injectors';
import { testReduxComponentSaga } from './saga';
import { TestReduxComponentState } from './types';

export const initialState: TestReduxComponentState = {
  counter: 0,
};

const slice = createSlice({
  name: 'testReduxComponent',
  initialState,
  reducers: {
    // someAction(state, action: PayloadAction<any>) {},
    incrementCounter(state) {
      state.counter += 1;
    },
    decrementCounter(state) {
      state.counter -= 1;
    },
    resetCounter(state) {
      Object.assign(state, initialState);
    },
  },
});

export const { actions: testReduxComponentActions } = slice;

export const useTestReduxComponentSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: testReduxComponentSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useTestComponentSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
