/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { InjectedReducersType } from '../utils/types/injector-typings';

export function createReducer(
  injectedReducers: InjectedReducersType = {},
): Reducer {
  if (Object.keys(injectedReducers).length === 0) {
    // Use `unknown` instead of `any` for better type safety
    return (state: unknown = {}) => state as typeof state;
  } else {
    return combineReducers({
      ...injectedReducers,
    });
  }
}
