/**
 *
 * Gearbox7
 *
 */
import { Box, Typography } from '@mui/material';
import * as React from 'react';
import geARBox7 from '../../assets/geARBox7.gif';

export function Gearbox7() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        justifyContent: 'center',
      }}
    >
      <Typography
        variant="h3"
        component="div"
        fontFamily="Space Mono, sans-serif"
        fontWeight="bold"
        sx={{
          background:
            'linear-gradient(90deg, #122e68 0%, #436aba 25%, #7a9add 50%, #c1d7ff 75%, #aaa57d 100%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        ge<span style={{ fontVariantCaps: 'all-small-caps' }}>ARBOX</span>
      </Typography>
      <img
        src={geARBox7}
        alt="geARBox7"
        height="50"
        style={{
          filter: 'drop-shadow(0 0 1em #61dafbcb)',
        }}
      />
    </Box>
  );
}
