import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state?.testSagaComponent || initialState;

export const selectTestSagaComponent = createSelector(
  [selectSlice],
  state => state,
);

export const selectTodos = createSelector([selectSlice], state => state?.data);
export const selectIsLoading = createSelector(
  [selectSlice],
  state => state?.isLoading,
);
export const selectError = createSelector([selectSlice], state => state?.error);
