/**
 *
 * HomePage
 *
 */
// import * as React from 'react';

// interface Props {}

// export function HomePage(props: Props) {

// const style = {padding: '1rem'}

// return (
//   <div style={style}>
//   This is HomePage.
// </div>
// );

// };

// File: src/components/HomePage.js

import React from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
} from '@mui/material';
import backgroundImage from '../../assets/coffee_table_req.jpg';
import ArkwareBg from '../../assets/Arkware-bg.png';
import { useNavigate } from 'react-router-dom';

export function HomePage() {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/about');
  };
  return (
    <Box>
      {/* Hero Section */}
      <Box
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '60vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#fff',
          textAlign: 'center',
          p: 2,
          backgroundColor: 'rgba(0, 0, 0, 0.6)', // overlay for better text contrast
        }}
      >
        <Box
          sx={{
            width: '100vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center', // Center content horizontally
            gap: { xs: 1, md: 2 }, // Smaller gap on small screens
            flexDirection: { xs: 'column', md: 'row' }, // Stack on smaller screens
            textAlign: { xs: 'center', md: 'left' }, // Center text on small screens
            // backgroundColor: 'rgba(255, 255, 255, 0.15)',
            py: '20px',
          }}
        >
          <Typography
            variant="h3"
            component="h1"
            sx={{
              fontWeight: 'bold',
              fontSize: { xs: '2rem', sm: '2.5rem', md: '3.5rem' }, // Adjust font size at different breakpoints
              fontFamily: 'Space Mono, sans-serif',
              color: '#ffffff97',
              //           color: '#aaa57d',
              //           textShadow: `
              //   -2px -2px 0 #00000037,
              //   2px -2px 0 #00000037,
              //   -2px 2px 0 #00000037,
              //   2px 2px 0 #00000037
              // `,
            }}
          >
            Arkware
          </Typography>
          <Box
            component="img"
            src={ArkwareBg}
            alt="Arkware Logo"
            sx={{
              height: { xs: '100px', sm: '150px', md: '200px' }, // Responsive height
              width: 'auto', // Maintain aspect ratio
              maxWidth: '100%', // Prevents image from overflowing container on small screens
              animation: 'rotateSlant 3s infinite linear',
              transformOrigin: 'center',
              '@keyframes rotateSlant': {
                '0%': { transform: 'rotateY(0deg) rotateX(15deg)' },
                '100%': { transform: 'rotateY(360deg) rotateX(15deg)' },
              },
            }}
          />
        </Box>
        <Typography
          variant="h5"
          component="h2"
          sx={{
            mt: 2,
            fontFamily: 'Space Mono, sans-serif',
          }}
        >
          Apps for Everyone
        </Typography>
        <Button
          variant="contained"
          sx={{
            mt: 4,
            px: 5,
            py: 1.5,
            fontSize: '1rem',
            borderRadius: '8px',
            backgroundColor: '#aaa57d', // gold button
            color: '#122e68',
            ':hover': { backgroundColor: '#c1d7ff' },
            fontFamily: 'Space Mono, sans-serif',
          }}
          onClick={handleNavigate}
        >
          Discover More
        </Button>
      </Box>

      {/* Features Section */}
      <Container
        sx={{ py: 8, textAlign: 'center', backgroundColor: '#f3f4f6' }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          gutterBottom
          sx={{ color: '#122e68', fontFamily: 'Space Mono, sans-serif' }}
        >
          Why Choose Arkware?
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ mb: 4, fontFamily: 'Space Mono, sans-serif' }}
        >
          We create apps that make a difference. Our approach is focused on
          function, quality, and user experience.
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {[
            {
              title: 'Modern Design',
              description: 'Sleek, intuitive, and user-focused.',
              icon: '🎨',
            },
            {
              title: 'Performance Optimized',
              description: 'Reliable, responsive and efficient apps.',
              icon: '⚡',
            },
            {
              title: 'Secure & Private',
              description: 'Built with privacy and security in mind.',
              icon: '🔒',
            },
          ].map((feature, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Paper
                elevation={3}
                sx={{
                  p: 4,
                  borderRadius: '16px',
                  background:
                    'linear-gradient(135deg, #122e68, #436aba, #7a9add, #c1d7ff)',
                  color: '#fff',
                  fontFamily: 'Space Mono, sans-serif',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center', // Center content vertically
                  alignItems: 'center', // Center content horizontally
                  overflow: 'hidden', // Hide overflow if content exceeds height
                  textAlign: 'center',
                }}
              >
                <Typography
                  variant="h3"
                  sx={{ mb: 1, fontFamily: 'Space Mono, sans-serif' }}
                >
                  {feature.icon}
                </Typography>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  sx={{ fontFamily: 'Space Mono, sans-serif' }}
                >
                  {feature.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ mt: 1, fontFamily: 'Space Mono, sans-serif' }}
                >
                  {feature.description}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Testimonials Section */}
      <Box
        sx={{
          py: 8,
          backgroundColor: '#122e68',
          textAlign: 'center',
          color: '#fff',
        }}
      >
        <Container maxWidth="md">
          <Typography
            variant="h4"
            fontWeight="bold"
            gutterBottom
            sx={{ color: '#c1d7ff', fontFamily: 'Space Mono, sans-serif' }}
          >
            What Our Users Say
          </Typography>
          <Typography
            variant="body1"
            sx={{ mb: 4, fontFamily: 'Space Mono, sans-serif' }}
          >
            Trusted by users worldwide, Arkware&apos;s apps bring simplicity and
            intuitiveness to everyday tasks.
          </Typography>
          <Paper
            elevation={2}
            sx={{
              p: 4,
              maxWidth: '600px',
              mx: 'auto',
              backgroundColor: '#7a9add',
              borderRadius: '16px',
              color: '#122e68',
              fontFamily: 'Space Mono, sans-serif',
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontStyle: 'italic',
                mb: 2,
                fontFamily: 'Space Mono, sans-serif',
              }}
            >
              The app developed by Arkware has transformed the way I work.
              <br />
              It has significantly improved and streamlined my workflows.
              <br />
              Highly recommended!
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontFamily: 'Space Mono, sans-serif' }}
            >
              — Olivia Smith, Business Owner
            </Typography>
          </Paper>
        </Container>
      </Box>

      {/* Subscribe Section */}
      <Box
        sx={{
          py: 8,
          textAlign: 'center',
          background:
            'linear-gradient(90deg, #122e68, #436aba, #7a9add, #c1d7ff, #aaa57d)',
          color: '#ffffff',
        }}
      >
        <Container maxWidth="sm">
          <Typography
            variant="h4"
            fontWeight="bold"
            gutterBottom
            sx={{ fontFamily: 'Space Mono, sans-serif' }}
          >
            Stay in the Loop
          </Typography>
          <Typography
            variant="body1"
            sx={{ mb: 4, fontFamily: 'Space Mono, sans-serif' }}
          >
            Subscribe to receive the latest updates and insights on our new app
            launches.
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Your email address"
            fullWidth
            sx={{
              backgroundColor: '#ffffff',
              borderRadius: '8px',
              mb: 3,
              input: { color: '#122e68', fontFamily: 'Space Mono, sans-serif' },
            }}
          />
          <Button
            variant="contained"
            fullWidth
            sx={{
              py: 1.5,
              fontSize: '1rem',
              borderRadius: '8px',
              backgroundColor: '#122e68',
              color: '#fff',
              ':hover': { backgroundColor: '#436aba' },
              fontFamily: 'Space Mono, sans-serif',
            }}
          >
            Subscribe
          </Button>
          <Typography
            variant="caption"
            display="block"
            sx={{ mt: 3, opacity: 0.8, fontFamily: 'Space Mono, sans-serif' }}
          >
            © 2024 Arkware - All Rights Reserved.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
}

export default HomePage;
