// import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { call, put, delay, takeLatest } from 'redux-saga/effects';
import { testSagaComponentActions as actions } from '.';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Todo } from './types';

export function* testSagaComponentSaga() {
  yield takeLatest(actions.fetchTodosStarted.type, fetchTodosSaga);
}

function fetchTodosApi() {
  return axios.get<AxiosResponse<Todo[]>>('https://dummyjson.com/todos');
}

function* fetchTodosSaga() {
  try {
    const response: AxiosResponse<{ todos: Todo[] }> = yield call(
      fetchTodosApi,
    );
    yield delay(5000);
    yield put(actions.fetchTodosSuccess(response.data.todos));
  } catch (error) {
    const errorMessage = (error as AxiosError).message;
    yield put(actions.fetchTodosFailed(errorMessage));
  }
}
